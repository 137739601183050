import React from "react";
import "./slider.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export default function BtnSlider({ direction, moveSlide }) {
    console.log(direction, moveSlide);
    return (
        <button
            onClick={moveSlide}
            className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
        >
            {direction === "next" ? <FaAngleRight color="#fff" size={36} /> : <FaAngleLeft color="#fff" size={36} />}
        </button>
    );
}