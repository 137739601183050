import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "./BannerSlider/bannerSlider";

const Banner = () => {


  const data = useStaticQuery(graphql`
    query BannerGhostPost {
      allGhostPost(
        filter: {
          tags: { elemMatch: { name: { eq: "MY_BRAZIL" } } }
          featured: { eq: true }
        }
      ) {
        edges {
          node {
            ...GhostPostFields
          }
        }
      }
      allGhostPage(
        filter: {
          tags: { elemMatch: { name: { eq: "MY_BRAZIL" } } }
          featured: { eq: true }
        }
      ) {
        edges {
          node {
            ...GhostPageFields
          }
        }
      }
    }
  `);

  const posts = data?.allGhostPost?.edges;
  const pages = data?.allGhostPage?.edges;
  const featuredData = posts.concat(pages);


  return (
    <>
      <Slider featuredData={featuredData} />
    </>

  );
};

export default Banner;
